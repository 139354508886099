import { IconType as ReactIconType } from 'react-icons'
import {
    FaAngleDown,
    FaArrowCircleRight,
    FaBars,
    FaCode,
    FaMobileAlt,
    FaPencilRuler,
    FaPlus,
    FaRegEye,
    FaRegFileAlt,
    FaRegLightbulb,
    FaRegSmile,
    FaSearchPlus,
    FaTimes,
    FaUpload,
    FaUserFriends,
    FaWrench,
    FiRefreshCcw,
    IoIosGlobe,
    MdGroupAdd,
    MdPolymer,
    FaRobot
} from 'react-icons/all'
import {
    FaAngleLeft,
    FaAngleRight,
    FaArrowCircleDown,
    FaArrowCircleUp,
    FaCaretRight,
    FaCheck,
    FaDesktop,
    FaFacebookF,
    FaHeadphones,
    FaInstagram,
    FaLinkedinIn,
    FaLinkedin,
    FaPlusCircle,
    FaRegEnvelope,
    FaShare,
    FaTwitter
} from 'react-icons/fa'

const make = (icon: ReactIconType): ReactIconType => icon
export type IconType = ReactIconType

type Icons = {
    Facebook: IconType
    Headphones: IconType
    Instagram: IconType
    Linkedin: IconType
    LinkedinV2: IconType
    Envelope: IconType
    Twitter: IconType
    Share: IconType
    PlusCircle: IconType
    Check: IconType
    ArrowCircleDown: IconType
    ArrowCircleUp: IconType
    ArrowCircleRight: IconType
    Polymer: IconType
    Mobile: IconType
    Globe: IconType
    AddUsers: IconType
    Refresh: IconType
    Bulb: IconType
    Eye: IconType
    File: IconType
    Design: IconType
    Code: IconType
    Upload: IconType
    FindReplace: IconType
    Add: IconType
    Smile: IconType
    Users: IconType
    Menu: IconType
    Close: IconType
    CaretRight: IconType
    AngleLeft: IconType
    AngleRight: IconType
    AngleDown: IconType
    Wrench: IconType
    Desktop: IconType
    Robot: IconType
}

type Color = string

type IconColors = {
    facebook: Color
    twitter: Color
    linkedin: Color
}

export const iconColors: IconColors = {
    facebook: '#3b5998',
    twitter: '#38A1F3',
    linkedin: '#0077B5'
}

const icons: Icons = {
    Facebook: make(FaFacebookF),
    Headphones: make(FaHeadphones),
    Instagram: make(FaInstagram),
    Linkedin: make(FaLinkedinIn),
    LinkedinV2: make(FaLinkedin),
    Envelope: make(FaRegEnvelope),
    Twitter: make(FaTwitter),
    Share: make(FaShare),
    PlusCircle: make(FaPlusCircle),
    Check: make(FaCheck),
    ArrowCircleDown: make(FaArrowCircleDown),
    ArrowCircleUp: make(FaArrowCircleUp),
    ArrowCircleRight: make(FaArrowCircleRight),
    Polymer: make(MdPolymer),
    Mobile: make(FaMobileAlt),
    Globe: make(IoIosGlobe),
    AddUsers: make(MdGroupAdd),
    Refresh: make(FiRefreshCcw),
    Bulb: make(FaRegLightbulb),
    Eye: make(FaRegEye),
    File: make(FaRegFileAlt),
    Design: make(FaPencilRuler),
    Code: make(FaCode),
    Upload: make(FaUpload),
    FindReplace: make(FaSearchPlus),
    Add: make(FaPlus),
    Smile: make(FaRegSmile),
    Users: make(FaUserFriends),
    Menu: make(FaBars),
    Close: make(FaTimes),
    CaretRight: make(FaCaretRight),
    AngleLeft: make(FaAngleLeft),
    AngleRight: make(FaAngleRight),
    AngleDown: make(FaAngleDown),
    Wrench: make(FaWrench),
    Desktop: make(FaDesktop),
    Robot: make(FaRobot)
}
export default icons
