import { Lang } from 'src/i18n/lang/types'

export type PageInfo = {
    name: string
    lang: Lang
    path: string
}

export class PageNavigator {
    constructor(
        private currentPage: string,
        private lang: Lang,
        private pages: PageInfo[]
    ) {}

    linkTo = (name: string) => this.getPage(name, this.lang).path

    private getPage = (name: string, lang: Lang): PageInfo => {
        let p = this.pages.find((p) => p.name === name && p.lang === lang)

        if (!p && name === 'career' && ['en', 'de'].includes(lang)) {
            p = this.pages.find((p) => p.name === 'index' && p.lang === lang)
        }

        if (!p) throw new Error(`Page ${name} not found`)

        return p
    }

    currentPathIn = (lang: Lang) => this.getPage(this.currentPage, lang).path
}
