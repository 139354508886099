import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactNode } from 'react'
import { Address } from 'src/components/contact/ContactData'
import Container from 'src/components/Container'
import { Lang } from 'src/i18n/lang/types'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import Icon from 'src/icons'
import { LabeledPage } from 'src/navigation/Navigation'
import { css, darken } from 'src/style/css'
import media from 'src/img/media'
import { Link } from 'src/components/Link'

import { Theme } from 'src/style/Theme'
import { grid } from 'src/style/mixins'

const footerPrimaryLinkStyle = (th: Theme) => css`
    color: ${th.colors.white};
`

const footerSecondaryLinkStyle = (th: Theme) => css`
    color: ${th.colors.lightGray};
`

const footerSecondaryIconStyle = (th: Theme) => css`
    color: ${th.colors.gray};
    width: ${th.fontSize.lg};
    height: ${th.fontSize.lg};
    margin-left: ${th.spacing.xs};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${th.fontSize.md};
`

const listStyle = (th: Theme) => css`
    > *:not(:last-of-type) {
        margin-bottom: ${th.spacing.xs};
    }
`

const Labeled = ({
    label,
    children
}: {
    label: string
    children: ReactNode
}) => (
    <>
        <strong>{label}: </strong>
        <span>{children}</span>
    </>
)

type FooterData = {
    contactJson: {
        companyData: {
            companyName: string
            billing: {
                additional: {
                    companyId: string
                    vatId: string
                }
                address: Address
            }
            contact: {
                email: string
                phone: string
            }
            social: {
                facebook: string
                twitter: string
                linkedin: string
                instagram: string
            }
        }
    }
    pages: LabeledPage[]
    buildInfo: {
        buildYear: string
    }
}

export const useFooterData = (lang: Lang): FooterData => {
    const data = useStaticQuery(
        graphql`
            query FooterQuery {
                contactJson {
                    companyData {
                        companyName
                        billing {
                            additional {
                                companyId
                                vatId
                            }
                            address {
                                name
                                street
                                city
                                postCode
                                country
                            }
                        }
                        contact {
                            email
                            phone
                        }
                        social {
                            facebook
                            twitter
                            linkedin
                            instagram
                        }
                    }
                }
                allSitePage(
                    filter: { fields: { isFooterPage: { eq: true } } }
                    sort: { fields: fields___navOrder }
                ) {
                    nodes {
                        path
                        fields {
                            label
                        }
                        context {
                            lang
                        }
                    }
                }
                buildInfo {
                    buildYear
                }
            }
        `
    )
    // TODO simplify, its used also in Navigation.tsx
    data.pages = data.allSitePage.nodes
        .filter((n: any) => !lang || n.context.lang === lang)
        .map((n: any) => ({
            path: n.path,
            label: n.fields.label
        }))
    return data
}

const Footer = ({ lang, msgs }: { lang: Lang; msgs: Msgs }) => {
    const { contactJson, buildInfo, pages } = useFooterData(lang)
    const { companyData } = contactJson
    return (
        <footer
            css={(th: Theme) => css`
                background-color: ${th.colors.darkGray};
                color: ${th.colors.white};
                font-size: ${th.fontSize.sm};
            `}
        >
            <Container
                css={css`
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                `}
            >
                <ul
                    itemProp="address"
                    itemType="https://schema.org/PostalAddress"
                    css={listStyle}
                >
                    <li itemProp="name">
                        <strong>{companyData.billing.address.name}</strong>
                    </li>
                    <li itemProp="streetAddress">
                        {companyData.billing.address.street}
                    </li>
                    <li itemProp="addressLocality">
                        {companyData.billing.address.postCode}{' '}
                        {companyData.billing.address.city}
                    </li>
                    <li itemProp="addressRegion">
                        {companyData.billing.address.country}
                    </li>
                </ul>

                <ul css={listStyle}>
                    <li>
                        <Labeled label={t(msgs.billing.vatId)}>
                            {companyData.billing.additional.vatId}
                        </Labeled>
                    </li>
                    <li>
                        <Labeled label={t(msgs.billing.companyId)}>
                            {companyData.billing.additional.companyId}
                        </Labeled>
                    </li>
                </ul>

                <ul css={listStyle}>
                    <li>
                        <Labeled label={t(msgs.contact.phone)}>
                            <a
                                aria-label={'phone-number'}
                                title={companyData.contact.phone}
                                href={`tel:${companyData.contact.phone}`}
                                itemProp="telephone"
                                css={footerPrimaryLinkStyle}
                            >
                                {companyData.contact.phone}
                            </a>
                        </Labeled>
                    </li>
                    <li>
                        <Labeled label={t(msgs.contact.email)}>
                            <a
                                aria-label={'email'}
                                title={companyData.contact.email}
                                href={`mailto:${companyData.contact.email}`}
                                itemProp="email"
                                css={footerPrimaryLinkStyle}
                            >
                                {companyData.contact.email}
                            </a>
                        </Labeled>
                    </li>
                </ul>

                <ul css={(th: Theme) => listStyle(th)}>
                    {pages.map((p, i) => (
                        <li key={`footer-pages-${i}`}>
                            <Link
                                to={p.path}
                                aria-label={p.label}
                                css={(th: Theme) => css`
                                    display: inline-block;
                                    color: ${th.colors.white};
                                    background: url(${media.footerLinkArrow})
                                        no-repeat left 80%;
                                    padding-left: ${th.spacing.sm};
                                `}
                            >
                                {p.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </Container>

            <div
                css={(th: Theme) => css`
                    background-color: ${darken(
                        th.brightness.sm,
                        th.colors.darkGray
                    )};
                    color: ${th.colors.lightGray};
                `}
            >
                <Container
                    css={(th: Theme) => css`
                        ${grid({ minWidth: '20' })}
                        grid-template-columns: 1fr 1fr;
                        @media (max-width: ${th.mediaQuery.md}) {
                            text-align: center;
                            grid-template-columns: 1fr;
                        }
                    `}
                >
                    <span
                        css={css`
                            margin-bottom: 0 !important;
                        `}
                    >
                        {t(msgs.copyrights, {
                            year: buildInfo.buildYear,
                            company: companyData.companyName
                        })}
                    </span>

                    <div
                        css={(th: Theme) => css`
                            @media (max-width: ${th.mediaQuery.md}) {
                                display: none;
                            }
                        `}
                    >
                        <div
                            css={(th: Theme) => css`
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: ${th.spacing.sm};
                            `}
                        >
                            <a
                                title="Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={companyData.social.facebook}
                                css={footerSecondaryIconStyle}
                                aria-label={'Facebook'}
                            >
                                <Icon.Facebook />
                            </a>
                            <a
                                title="Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={companyData.social.twitter}
                                css={footerSecondaryIconStyle}
                                aria-label={'Twitter'}
                            >
                                <Icon.Twitter />
                            </a>

                            <a
                                title="LinkedIn"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={companyData.social.linkedin}
                                css={footerSecondaryIconStyle}
                                aria-label={'LinkedIn'}
                            >
                                <Icon.Linkedin />
                            </a>
                            <a
                                title="Instagram"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={companyData.social.instagram}
                                css={footerSecondaryIconStyle}
                                aria-label={'Instagram'}
                            >
                                <Icon.Instagram />
                            </a>
                        </div>
                        <div
                            css={(th: Theme) => css`
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                > * {
                                    margin-left: ${th.spacing.sm};
                                }
                            `}
                        >
                            <Icon.Envelope />
                            <a
                                title={companyData.contact.email}
                                href={`mailto:${companyData.contact.email}`}
                                css={footerSecondaryLinkStyle}
                                aria-label={'email'}
                            >
                                {companyData.contact.email}
                            </a>
                            <span className="middot"> · </span>
                            <Icon.Headphones />
                            <a
                                title={companyData.contact.phone}
                                href={`tel:${companyData.contact.phone}`}
                                css={footerSecondaryLinkStyle}
                                aria-label={'phone-number'}
                            >
                                {companyData.contact.phone}
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    )
}

export default Footer
