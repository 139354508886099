import React from 'react'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

const Badge = ({ value, style }: { value: number; style?: any }) => {
    return (
        <span
            css={(th: Theme) => css`
                font-family: sans-serif;
                background-color: ${th.colors.primary};
                color: ${th.colors.white};
                border-radius: 10px;
                font-size: 0.9em;
                font-weight: ${th.fontWeight.bold};
                padding: 0.1em 0.5em 0.1em 0.6em;
                text-align: center;
                line-height: 1.4em;
                display: inline-block;
                ${style}
            `}
        >
            {value}
        </span>
    )
}

export default Badge
