import React, { ReactNode } from 'react'
import Badge from 'src/components/ui/Badge'
import { css } from 'src/style/css'
import { useCareerPositions } from 'src/api_netlify/useCareerPositions'

const WithCareerBadge = ({ children }: { children: ReactNode }) => {
    const { data } = useCareerPositions()
    const careerPositionCount = data ? data.length : 0
    return (
        <span
            css={css`
                position: relative;
            `}
        >
            {children}
            {careerPositionCount > 0 && (
                <Badge
                    value={careerPositionCount}
                    style={css`
                        position: absolute;
                        top: -1em;
                    `}
                />
            )}
        </span>
    )
}

export default WithCareerBadge
