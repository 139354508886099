import resourcesSk from 'src/i18n/translation/resources/sk.json'
import resourcesDe from 'src/i18n/translation/resources/de.json'
import resourcesEn from 'src/i18n/translation/resources/en.json'
import { Msgs } from 'src/i18n/translation/types'
import { flatten } from 'src/util/flatten'

type Resources = {
    [key: string]: Msgs
}

// TODO add unit tests that json have the expected structure ?
const resources: Resources = {
    // @ts-ignore
    sk: resourcesSk,
    // @ts-ignore
    de: resourcesDe,
    // @ts-ignore
    en: resourcesEn
}

type FlatResources = {
    [key: string]: {
        [key: string]: string
    }
}
export const flatResources: FlatResources = {
    sk: flatten(resourcesSk),
    de: flatten(resourcesDe),
    en: flatten(resourcesEn)
}

export default resources
