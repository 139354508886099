import { defaultLanguage, languages } from 'src/i18n/lang/languages'

const stripLanguageFromPath = (path: string): string => {
    const languagePrefixes = languages.map((lang) => `/${lang}`)
    let strippedPath = path

    languagePrefixes.forEach((prefix) => {
        const regex = new RegExp(`^(${prefix})+`)
        strippedPath = strippedPath.replace(regex, '')
    })

    return strippedPath
}

export const langPath = (lang: string, path: string): string => {
    /*
    Ak ide user browser back a ma iny ako defaultny jazyk, tak treba takto skladat cestu, inak sa
    vzdy prependoval vybrany jazyk
    */
    path = stripLanguageFromPath(path)
    const languagedPath = `/${lang}${path}`
    return lang === defaultLanguage ? path : languagedPath
}
