import { css } from 'src/style/css'

export const grid = ({
    minWidth,
    gap,
    auto = 'fit'
}: {
    minWidth: string
    gap?: string
    auto?: 'fit' | 'fill'
}) => css`
    display: grid;
    grid-template-columns: repeat(${`auto-${auto}`}, minmax(${minWidth}, 1fr));
    ${gap && `grid-gap: ${gap};`}
`
