import { templateString } from 'src/i18n/templateString'

const templateRegex = /{{/

const isTemplate = (msg: string) => !!msg.match(templateRegex)

const isTemplateCache: { [key: string]: boolean } = {}

export const t = (msg: string, args?: { [key: string]: string }) => {
    if (!msg) throw new Error('No message')

    if (!args) {
        let isTemp = isTemplateCache[msg]
        if (isTemp == null) {
            isTemp = isTemplate(msg)
            isTemplateCache[msg] = isTemp
        }
        if (isTemp)
            throw new Error(
                `'${msg}' is a template but no arguments were provided`
            )

        return msg
    }

    return templateString(msg, args)
}
