import React, { useEffect, useState } from 'react'
import { CookieNotice } from 'gatsby-cookie-notice'
import './style.sass'
import { PageContext } from 'src/page/types'
import { isCookieSet } from 'src/util/isCookieSet'

type CookieBarProps = {
    pageContext: PageContext
}

const CookieBar = ({ pageContext }: CookieBarProps) => {
    const { msgs } = pageContext
    const cookies = [
        {
            name: 'necessary',
            editable: false,
            default: true,
            title: 'Essentiel',
            text: msgs.cookieBar.essentials
        },
        {
            name: 'gatsby-gdpr-google-analytics',
            editable: true,
            default: true,
            title: 'Google Analytics',
            text: msgs.cookieBar.analytics
        }
    ]
    const [isVisible, setIsVisible] = useState(!isCookieSet('necessary'))

    useEffect(() => {
        // eslint-disable-next-line no-undef
        const handleClick = (event: Event) => {
            // eslint-disable-next-line no-undef
            const target = event.target as HTMLElement
            if (
                target.classList.contains('accept') ||
                target.classList.contains('decline')
            ) {
                setIsVisible(false)
            }
        }
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    const updateOverlay = (): void => {
        if (typeof document !== 'undefined') {
            const overlay = document.querySelector('.cookie-overlay')
            if (isVisible) {
                overlay?.classList.add('display-block')
            }
            if (!isVisible) {
                overlay?.classList.remove('display-block')
                overlay?.classList.add('display-none')
            }
        }
    }

    updateOverlay()

    return (
        <div>
            <CookieNotice
                cookies={cookies}
                backgroundWrapperClasses={'cookie-bar-wrapper'}
                backgroundClasses={'cookie-bar'}
                wrapperClasses={'buttons-wrapper'}
                acceptButtonClasses={'button accept'}
                acceptButtonText={msgs.cookieBar.accept}
                personalizeButtonClasses={'button personalize'}
                personalizeButtonText={msgs.cookieBar.settings}
                declineButtonClasses={'button decline'}
                declineButtonText={msgs.cookieBar.decline}
                personalizeValidationClasses={'button accept'}
                personalizeValidationText={msgs.cookieBar.save}
                cookieMapClasses={'cookie-map'}
                personalizeValidationWrapperClasses={
                    'float-right validate-wrapper'
                }
                buttonWrapperClasses={'accept-decline-wrapper'}
            >
                <h4>{msgs.cookieBar.title}</h4>
                <p>{msgs.cookieBar.description}</p>
            </CookieNotice>
            {isVisible && (
                <div className={'cookie-overlay force--consent'}></div>
            )}
        </div>
    )
}

export default CookieBar
