import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'
import { getWindow } from 'src/util/getWindow'

export type WindowScrollPosition = {
    y: number
    documentHeight: number
    heightRatio: number
}

const useBodyScroll = (): WindowScrollPosition => {
    const windowObj = getWindow({
        scrollY: 0,
        document: { body: { offsetHeight: 1 } }
    })
    const getCurrent = () => ({
        y: windowObj.scrollY,
        documentHeight: windowObj.document.body.offsetHeight,
        heightRatio:
            // Because on load offsetHeight is 0 and heightRatio was NaN
            windowObj.scrollY / (windowObj.document.body.offsetHeight || 1)
    })
    const [position, set] = useState<WindowScrollPosition>(getCurrent())
    const listener = () => {
        set(getCurrent)
    }
    const handleScroll = throttle(listener, 100)

    useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return position
}

export default useBodyScroll
