const paramRegex = /^{{\s*(\w+)\s*}}$/
const toParamName = (paramStr: string) => {
    const match = paramStr.match(paramRegex)

    if (match === null || match.length < 1)
        throw new Error(`Invalid param notation '${paramStr}'`)

    return match[1]
}

type CompiledTemplate = {
    split: string[]
    params: string[]
}

const compile = (template: string): CompiledTemplate => {
    const paramsStrs = template.match(/{{\s*.*?\s*}}/g)!
    const params = paramsStrs.map(toParamName)
    const rex = new RegExp(paramsStrs.join('|'))
    const split = template.split(rex)

    return {
        split,
        params
    }
}

const combine = (
    template: CompiledTemplate,
    args: { [key: string]: string }
): string => {
    const { split, params } = template

    if (Object.keys(args).length !== params.length)
        throw new Error(
            JSON.stringify(args) +
                ' is expected to have exactly keys: ' +
                params
        )

    // Zip
    let res = ''
    for (let i = 0; i < split.length - 1; i++) {
        const param = params[i]
        const val = args[param]
        if (val == null)
            throw new Error(
                `Found no value for param ${param} in ` + JSON.stringify(args)
            )
        res = res + split[i] + val
    }
    res = res + split[split.length - 1]

    return res
}

const compilationCache: { [key: string]: CompiledTemplate } = {}

export const templateString = (
    template: string,
    args: { [key: string]: string }
): string => {
    let compiled = compilationCache[template]

    if (!compiled) compiled = compile(template)

    compilationCache[template] = compiled

    return combine(compiled, args)
}
