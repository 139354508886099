import { Lang } from 'src/i18n/lang/types'
import { languages } from 'src/i18n/lang/languages'
import { LangDictionary } from 'src/i18n/translation/types'

export const tData = (data: LangDictionary, lang: Lang): string => {
    if (!data) throw new Error('No data')
    if (!lang) throw new Error('No lang')
    if (!languages.includes(lang))
        throw new Error('Lang is not from allowed languages')
    languages.forEach((lng) => {
        // @ts-ignore
        if (!data[lng])
            throw new Error(`Data is missing translation for lang: ${lng}`)
    })
    // @ts-ignore
    if (typeof data[lang] !== 'string')
        throw new Error('Data translation is not string')
    // @ts-ignore
    return data[lang]
}
