import {
    CareerPositionInfo,
    CareerPositionInfoDB
} from 'src/model/careerPositionType'
import { useQuery } from 'react-query'

const parseCareerStringToArray = (str: string) => {
    const x = str
        ? str
              .split('\n')
              .map((s) => s.trim())
              .filter((s) => s.length > 0 && s !== '-')
              .map((s) => s.replace(/^-/, '').trim())
        : []
    return x
}

export const useCareerPositions = () => {
    return useQuery<any, any, CareerPositionInfo[]>('careerList', () =>
        fetch(
            'https://api.garwan.com/endpoint/career_list.php?hash=b94135f56a3341af5de6de466a0be5c2',
            {
                method: 'get',
                mode: 'cors'
            }
        )
            .then((r) => r.json())
            .then((data: CareerPositionInfoDB[]) => {
                if (Array.isArray(data)) {
                    return data.map((d) => ({
                        id: d.id,
                        title: d.name,
                        salary: d.sallary,
                        positionId: parseInt(d.recru_id),
                        perks: parseCareerStringToArray(d.benefits),
                        order: parseInt(d.offer_order),
                        location: d.locality,
                        jobType: d.type_of_employment,
                        jobStart: d.offer_from,
                        description: d.short_description,
                        expectations: parseCareerStringToArray(
                            d.other_informations
                        ),
                        tasks: parseCareerStringToArray(d.job_offer_information)
                    }))
                }
                return []
            })
    )
}
