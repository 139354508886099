import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Footer from 'src/components/Footer'
import { PageContext } from 'src/page/types'
import {
    MyContext,
    MyContextConsumer,
    MyContextProvider
} from 'src/components/MyContext'
import { RenderCb } from 'src/components/ui/RenderCb'
import { getAllPages } from 'src/page/pages'
import Topbar from 'src/navigation/Topbar'
import { IntlProvider } from 'react-intl'
import { flatResources } from 'src/i18n/translation/resources'
import { t } from 'src/i18n/translation/t'
import { getImage } from 'gatsby-plugin-image'
import { globalHistory } from '@reach/router'
import { isHomePage, isReferencesPage } from 'src/navigation/pages'
import { Meta } from 'src/components/Meta'
import useSiteMetadata from 'src/components/SiteMetadata'
import { Lang } from 'src/i18n/lang/types'
import { useEnv } from 'src/hooks/useEnv'
import CookieBar from 'src/components/cookiebar'

const StaticMeta = ({ lang }: { lang: Lang }) => {
    const SITE_NAME = 'Garwan.sk'
    const { favicon } = useSiteMetadata()
    const { isProd } = useEnv()
    return (
        <Helmet>
            <html key="meta-lang" lang={lang} />
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
            />
            <meta name="author" content={SITE_NAME} />
            <meta property="og:locale" content={lang} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={SITE_NAME} />
            <meta
                name="robots"
                content={isProd ? 'index,follow' : 'no-index, no-follow'}
            />
            <meta name="twitter:card" content="summary" />
            <link
                rel="icon"
                type="image/png"
                href={getImage(favicon)?.images?.fallback?.src}
                sizes="16x16"
            />
            <meta name="theme-color" content="#fff" />
        </Helmet>
    )
}

type Layout = {
    children: RenderCb<{ ctx: MyContext }> | ReactNode
    overlayNav?: boolean
    pageCtx: PageContext
}

const Layout = ({ children, pageCtx, overlayNav = false }: Layout) => {
    const allPages = getAllPages()
    const myContext = MyContext.create(pageCtx, allPages)

    const lang = pageCtx.lang
    const messages = flatResources[pageCtx.lang]
    const title = t(messages['meta.title'])
    const description = t(messages['meta.desc'])
    const { favicon } = useSiteMetadata()

    // TODO: Temporary solution for Clutch Widget rendering
    // On nav to HOME or REFERENCES refresh page
    useEffect(() => {
        return globalHistory.listen(({ action, location }) => {
            if (
                isHomePage(location.pathname) ||
                isReferencesPage(location.pathname)
            ) {
                window.location.reload()
            }
        })
    }, [])
    return (
        <div>
            <StaticMeta lang={lang} />
            <Meta
                title={title}
                description={description}
                image={getImage(favicon)?.images?.fallback?.src}
            />
            <IntlProvider locale={lang} messages={messages}>
                <MyContextProvider value={myContext}>
                    <div>
                        <Topbar
                            lang={pageCtx.lang}
                            overlayContent={overlayNav}
                        />
                        <MyContextConsumer>
                            {(ctx) =>
                                typeof children === 'function'
                                    ? children({ ctx })
                                    : children
                            }
                        </MyContextConsumer>
                        <Footer lang={pageCtx.lang} msgs={pageCtx.msgs} />
                        <CookieBar pageContext={pageCtx} />
                    </div>
                </MyContextProvider>
            </IntlProvider>
        </div>
    )
}

export default Layout
