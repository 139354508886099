// @ts-ignore
import favicon from 'src/img/favicon-color.png'
// @ts-ignore
import footerLinkArrow from 'src/img/widget-link-dark.png'
// @ts-ignore
import caretDown from 'src/img/caret.png'
// @ts-ignore
import marker from 'src/img/contact-map-logo.png'

const imgs = {
    favicon,
    footerLinkArrow,
    caretDown,
    marker
}
export default imgs
