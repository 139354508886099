import { graphql } from 'gatsby'
import { ImageSrc } from 'src/components/Image'
import { Lang } from 'src/i18n/lang/types'
import { langPath } from 'src/i18n/langPath'
import { ServiceInfo } from 'src/model/serviceType'

export type ServiceQueryResult = ServiceInfo & {
    image: ImageSrc
}

export type WrappedServiceQueryResult = {
    services: {
        id: ServiceQueryResult
    }[]
}

export const unwrapServiceFromQueryAndSetLangPath = (
    r: WrappedServiceQueryResult,
    lang: Lang
): ServiceQueryResult[] =>
    r.services.map((i) => {
        const serv = i.id
        serv.path = langPath(lang, serv.path)
        if (serv.subservices?.length) {
            serv.subservices = serv.subservices.map((ss) => ({
                ...ss,
                path: langPath(lang, ss.path)
            }))
        }
        return serv
    })

export const serviceFragment = graphql`
    fragment SoloService on Service {
        title {
            sk
            en
            de
        }
        image {
            ...SrcImages
        }
        description {
            sk
            en
            de
        }
        descriptionItems {
            sk
            en
            de
        }
        descriptionTechnologies {
            sk {
                leftCol {
                    id
                    title
                    items
                }
                rightCol {
                    id
                    title
                    items
                }
            }
            de {
                leftCol {
                    id
                    title
                    items
                }
                rightCol {
                    id
                    title
                    items
                }
            }
            en {
                leftCol {
                    id
                    title
                    items
                }
                rightCol {
                    id
                    title
                    items
                }
            }
        }
        download {
            text {
                sk
                en
                de
            }
            files {
                filename {
                    sk
                    en
                    de
                }
                path {
                    sk
                    en
                    de
                }
            }
            button {
                sk
                de
                en
            }
        }
        path
        serviceId
    }
    fragment Services on ServiceOrderJson {
        services {
            id {
                ...SoloService
                subservices {
                    ...SoloService
                }
            }
        }
    }
    fragment ServiceDescriptionTechnologiesLeftCol on descriptionTechnologies {
        leftCol {
            id
            title {
                sk
                en
                de
            }
            items {
                sk
                en
                de
            }
        }
    }
    fragment ServiceDescriptionTechnologiesRightCol on descriptionTechnologies {
        rightCol {
            id
            title {
                sk
                en
                de
            }
            items {
                sk
                en
                de
            }
        }
    }
`
