import React, { ComponentType } from 'react'
import { Link as GatsbyLink } from 'gatsby'

type LinkProps = {
    to: string
    className?: string
    activeStyle?: Record<string, any>
    target?: string
    download?: any
}

export const Link: ComponentType<LinkProps> = (p) => {
    return <GatsbyLink {...p} />
}
