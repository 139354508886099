import React, { ComponentType } from 'react'
import { languages } from 'src/i18n/lang/languages'
import { LabeledPage } from 'src/navigation/Navigation'
import { getContext } from 'src/components/MyContext'

type LangSwitcherProps = {
    comp: ComponentType<{
        page: LabeledPage
    }>
}

const LangSwitcher: ComponentType<LangSwitcherProps> = ({ comp: Comp }) => {
    const ctx = getContext()
    // @ts-ignore
    const activeLang = ctx.pageContext.lang
    const otherLangs = languages.filter((l) => l !== activeLang)

    return (
        <Comp
            page={{
                path: ctx.nav.currentPathIn(activeLang),
                label: activeLang,
                name: activeLang,
                subpages: otherLangs.map((l) => ({
                    path: ctx.nav.currentPathIn(l),
                    label: l,
                    name: l
                }))
            }}
        />
    )
}

export default LangSwitcher
