import React, { useContext } from 'react'
import { PageContext } from 'src/page/types'
import { langPath } from 'src/i18n/langPath'
import { PageInfo, PageNavigator } from 'src/components/PageNavigator'
import { TByKey } from 'src/i18n/useT'
import { flatResources } from 'src/i18n/translation/resources'
import { templateString } from 'src/i18n/templateString'
import { tData } from 'src/i18n/translation/tData'
import { TData } from 'src/i18n/useTranslateData'

export class MyContext {
    nav: PageNavigator
    private constructor(
        private pageContext: PageContext,
        private pages: PageInfo[]
    ) {
        this.nav = new PageNavigator(pageContext.name, pageContext.lang, pages)
    }

    linkTo = (name: string) => {
        const pagePath = name
        return langPath(this.pageContext.lang, pagePath)
    }

    getLang = () => this.pageContext.lang

    getMsgs = () => this.pageContext.msgs

    // TODO optimize
    t: TByKey = (key, args) => {
        const langResources = flatResources[this.pageContext.lang]
        const msg = langResources[key]

        // TODO very suboptimal
        if (!msg.includes('{{')) return msg

        return templateString(msg, args || {})
    }

    tData: TData = (data) => tData(data, this.pageContext.lang)

    static create = (pageContext: PageContext, pages: PageInfo[]) =>
        new MyContext(pageContext, pages)
}

// @ts-ignore
// eslint-disable-next-line no-undef
const nullContext: UiContext = null as UiContext

const Context = React.createContext<MyContext>(nullContext)

export const MyContextProvider = Context.Provider
export const MyContextConsumer = Context.Consumer

export const getContext = () => useContext(Context)
