import { graphql, useStaticQuery } from 'gatsby'
import { PageInfo } from 'src/components/PageNavigator'

export const getAllPages = (): PageInfo[] => {
    const { allSitePage } = useStaticQuery(
        graphql`
            query allPagesQuery {
                allSitePage(filter: { context: { lang: { ne: null } } }) {
                    nodes {
                        path
                        context {
                            lang
                            name
                        }
                    }
                }
            }
        `
    )

    return allSitePage.nodes.map((n: any) => ({
        name: n.context.name,
        lang: n.context.lang,
        path: n.path
    }))
}
