import { useTheme } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'src/components/Link'
import React, { ComponentType, useEffect, useState } from 'react'
import { Lang } from 'src/i18n/lang/types'
import WithCareerBadge from 'src/navigation/WithCareerBadge'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { isTabletScreen } from 'src/style/ScreenSize'
import Icon from 'src/icons'
import LangSwitcher from 'src/i18n/LangSwitcher'
import { unwrapServiceFromQueryAndSetLangPath } from 'src/model/serviceQuery'
import { StaticImage } from 'gatsby-plugin-image';
export const NavItem: ComponentType<{
    page: LabeledPage
}> = ({ page }) => {
    const th = useTheme()
    const isTablet = isTabletScreen()
    const { path, label, name } = page
    const isCareer = name === 'career'
    return (
        <div
            css={css`
                display: flex;
                flex-direction: ${isTablet ? 'column' : 'rowp'};
                align-items: stretch;
                text-transform: uppercase;
                font-weight: ${th.fontWeight.bold};
                font-size: ${th.fontSize.xs};
                letter-spacing: 1px;
                position: relative;
                &:hover {
                    .dropdown-menu {
                        display: flex;
                    }
                }
            `}
        >
            <Link
                to={path}
                aria-label={label}
                css={css`
                    display: flex;
                    align-items: center;
                    padding: ${th.spacing.md} 12px;
                    position: relative;
                    &:hover {
                        color: ${th.colors.primary};
                    }
                `}
                activeStyle={{ color: th.colors.primary }}
            >
                {isCareer ? <WithCareerBadge>{label}</WithCareerBadge> : label}
            </Link>
            {page.subpages && (
                <div
                    className="dropdown-menu"
                    css={css`
                        position: ${isTablet ? 'static' : 'fixed'};
                        top: ${th.headerHeight};
                        display: ${isTablet ? 'flex' : 'none'};
                        flex-direction: column;
                        background: ${isTablet
                            ? 'transparent'
                            : th.colors.secondary};
                        ${!isTablet &&
                        `border-top: 2px solid ${th.colors.primary}`};
                        ${isTablet && `padding-left: ${th.spacing.md}`};
                    `}
                >
                    {page.subpages.map((di, idx) => (
                        <React.Fragment key={`${di.label}-${idx}`}>
                            <Link
                                to={di.path}
                                aria-label={di.label}
                                css={css`
                                    color: ${isTablet
                                        ? 'inherit'
                                        : th.colors.gray};
                                    padding: ${th.spacing.md} ${th.spacing.md};
                                    &:hover {
                                        color: ${isTablet
                                            ? th.colors.primary
                                            : th.colors.white};
                                    }
                                `}
                            >
                                {di.label}
                            </Link>
                            {di.subpages &&
                                di.subpages.map((ss) => (
                                    <Link
                                        key={`${ss.label}-subservice`}
                                        to={ss.path}
                                        aria-label={ss.label}
                                        css={css`
                                            color: ${isTablet
                                                ? 'inherit'
                                                : th.colors.gray};
                                            padding: ${th.spacing.md}
                                                ${th.spacing.md}
                                                ${th.spacing.md}
                                                ${th.spacing.lg};
                                            &:hover {
                                                color: ${isTablet
                                                    ? th.colors.primary
                                                    : th.colors.white};
                                            }
                                        `}
                                    >
                                        {ss.label}
                                    </Link>
                                ))}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    )
}

// TODO improve
const isServicePage = (path: string) =>
    path === '/services/' ||
    path === '/de/services/' ||
    path === '/sk/services/'
export const useNavigationData = (lang: Lang): NavigationData => {
    const { allSitePage, services } = useStaticQuery(
        graphql`
            query NAVIGATION_QUERY {
                allSitePage(
                    filter: { fields: { isMainPage: { eq: true } } }
                    sort: { fields: fields___navOrder }
                ) {
                    nodes {
                        path
                        fields {
                            label
                        }
                        context {
                            lang
                            name
                        }
                    }
                }
                services: serviceOrderJson {
                    ...Services
                }
            }
        `
    )

    const servicesSubpages = unwrapServiceFromQueryAndSetLangPath(
        services,
        lang
    ).map((n) => ({
        path: n.path,
        label: n.title[lang],
        subpages: n.subservices?.length
            ? n.subservices.map((ss) => ({
                  path: ss.path,
                  label: ss.title[lang]
              }))
            : null
    }))

    return {
        pages: allSitePage.nodes
            .filter((n: any) => !lang || n.context.lang === lang)
            .map((n: any) => ({
                path: n.path,
                label: n.fields.label,
                name: n.context.name,
                subpages: isServicePage(n.path) ? servicesSubpages : null
            }))
    }
}

const getNavigationStyle =
    (tablet: boolean, showRespoMenu: boolean) => (th: Theme) =>
        tablet
            ? css`
                  position: absolute;
                  top: ${th.headerHeight};
                  right: ${showRespoMenu ? '0' : '-100%'};
                  width: 250px;
                  height: calc(100vh - ${th.headerHeight});
                  background: ${th.colors.white};
                  display: flex;
                  flex-direction: column;
                  overflow-y: auto;
              `
            : css``

type Page = {
    path: string
    name: string
    subpages?: LabeledPage[] | null
}

export type LabeledPage = Page & {
    label: string
}

export type NavigationProps = {
    lang: Lang
    className?: string
    transparent?: boolean
}

const Navigation: ComponentType<NavigationProps> = ({
    lang,
    className,
    transparent = false
}) => {
    const isTablet = isTabletScreen()
    const th = useTheme()
    const [showRespoMenu, setShowRespoMenu] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const { pages } = useNavigationData(lang)
    // We have to change key on client load to trigger proper React hydration
    const [navKey, setNavKey] = useState('server')
    useEffect(() => {
        setNavKey('client')
    }, [])
    const homePath = pages[0].path
    const isTransparent = !isTablet && transparent
    const backgroundColor = isTransparent ? 'transparent' : th.colors.white

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    if (!isLoaded) {
        return null
    }

    return (
        <nav
            key={navKey}
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                background-color: ${backgroundColor};
                height: ${th.headerHeight};
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                padding: 0 ${th.spacing.md};
                z-index: ${th.zIndex.nav};
                width: 100%;
                transition: background-color 0.3s, box-shadow 0.3s;
                box-shadow: 0 0 ${isTransparent ? 0 : 10}px rgba(0, 0, 0, 0.1);
            `}
        >
            <Link
                to={homePath}
                aria-label={'homePage'}
                css={css`
                    height: 100%;
                    width: 170px;
                    display: flex;
                    align-items: center;
                `}
            >
                {isTransparent ? (
                    <StaticImage
                        src="../img/logo_white_new.png"
                        placeholder="none"
                        alt="Garwan"
                    />
                ) : (
                    <StaticImage
                        src="../img/logo_color_new.png"
                        placeholder="none"
                        alt="Garwan"
                    />
                )}
            </Link>
            {isTablet && (
                <div
                    css={(th: Theme) => css`
                        display: flex;
                        align-items: center;
                        > * {
                            font-size: ${th.fontSize.md};
                        }
                        padding-right: ${th.spacing.xl};
                    `}
                >
                    {showRespoMenu ? (
                        <Icon.Close onClick={() => setShowRespoMenu(false)} />
                    ) : (
                        <Icon.Menu onClick={() => setShowRespoMenu(true)} />
                    )}
                </div>
            )}

            <div
                css={(th: Theme) => css`
                    transition: all 0.3s ease-out;
                    display: flex;
                    align-items: stretch;
                    ${getNavigationStyle(isTablet, showRespoMenu)(th)};
                    > div > a {
                        color: ${isTransparent
                            ? th.colors.white
                            : th.colors.secondary};
                    }
                    padding-right: ${th.spacing.lg};
                `}
            >
                {pages.map((page, index) => (
                    <NavItem key={`navPage-${index}`} page={page} />
                ))}
                <LangSwitcher comp={NavItem} />
            </div>
        </nav>
    )
}

type NavigationData = {
    pages: LabeledPage[]
}

export default Navigation
