import { graphql, useStaticQuery } from 'gatsby'
import { SrcImagesFragment } from 'src/page/fragments'

type Metadata = {
    title: string
    description: string
    env: string
    apiUrl: string
    branch: string
    favicon: SrcImagesFragment
}

const useSiteMetadata = (): Metadata => {
    const { site, favicon } = useStaticQuery(
        graphql`
            query SITE_METADATA_QUERY {
                site {
                    siteMetadata {
                        title
                        description
                        env
                        apiUrl
                        branch
                    }
                }
                favicon: file(
                    sourceInstanceName: { eq: "src-images" }
                    name: { eq: "favicon-color" }
                ) {
                    ...SrcImages
                }
            }
        `
    )
    return {
        ...site.siteMetadata,
        favicon
    }
}

export default useSiteMetadata
