import React, { ComponentType } from 'react'
import { Helmet } from 'react-helmet'

type MetaProps = {
    title?: string
    description?: string
    image?: string
    url?: string
}

export const Meta: ComponentType<MetaProps> = ({
    title,
    description,
    image,
    url
}) => {
    return (
        <Helmet>
            <title key="meta-title">{title}</title>
            <meta
                key="meta-description"
                name="description"
                content={description}
            />
            <link key="meta-canonical" rel="canonical" href={url} />

            <meta key="meta-og:url" property="og:url" content={url} />
            <meta key="meta-og:title" property="og:title" content={title} />
            <meta
                key="meta-og:description"
                property="og:description"
                content={description}
            />
            <meta key="meta-og:image" property="og:image" content={image} />
            <meta
                key="meta-og:image:alt"
                property="og:image:alt"
                content={title}
            />
        </Helmet>
    )
}
