import React from 'react'
import { Lang } from 'src/i18n/lang/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import Navigation from 'src/navigation/Navigation'
import useBodyScroll from 'src/hooks/useBodyScroll'

const Topbar = ({
    lang,
    overlayContent = false
}: {
    lang: Lang
    overlayContent?: boolean
}) => {
    let transparentNavigation = overlayContent
    if (overlayContent) {
        const { heightRatio } = useBodyScroll()
        transparentNavigation = heightRatio < 0.001
    }
    return (
        <>
            <Navigation lang={lang} transparent={transparentNavigation} />
            {!overlayContent && (
                <div
                    css={(th: Theme) => css`
                        height: ${th.headerHeight};
                    `}
                />
            )}
        </>
    )
}

export default Topbar
