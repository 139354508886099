import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

const myWindow =
    typeof window === 'undefined'
        ? {
              innerWidth: 1920,
              innerHeight: 1000,
              addEventListener: (a: any, b: any) => {
                  'nothing'
              },
              removeEventListener: (a: any, b: any) => {
                  'nothing'
              }
          }
        : window

export const useWindowResize = (
    handle: (width: number, height: number) => void
) => {
    useEffect(() => {
        const listener = () => handle(myWindow.innerWidth, myWindow.innerHeight)
        myWindow.addEventListener('resize', throttle(listener, 300))

        return () => {
            myWindow.removeEventListener('resize', listener)
        }
    }, [])
}

const sizes = {
    xs: 360,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1600
}

export const ScreenSize = {
    sizes,
    isTabletScreen: (size: number) => size <= sizes.md
}

export const isTabletScreen = () => {
    const [v, setV] = useState(ScreenSize.isTabletScreen(myWindow.innerWidth))

    useWindowResize((w) => setV(ScreenSize.isTabletScreen(w)))

    return v
}

export const isScreenSize = (max: number) => {
    const isSize = (current: number) => current <= max

    const [v, setV] = useState(isSize(myWindow.innerWidth))

    useWindowResize((w) => setV(isSize(w)))

    return v
}
